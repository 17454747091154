import React from "react";
import RootRouter from "./router";
import "./styles/globals/index.scss";
import GoogleTagManager from "./components/GoogleTagManager";

function App() {
  return (
    <div className="App">
      <GoogleTagManager />
      <RootRouter />
    </div>
  );
}

export default App;
