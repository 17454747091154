import { useEffect } from 'react';

const GoogleTagManager = () => {
  const GTM_ID = process.env.REACT_APP_GTM_ID;

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      <!-- Google Tag Manager -->
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');
      <!-- End Google Tag Manager -->
    `;
    document.head.insertAdjacentElement('afterbegin', script);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <!-- Google Tag Manager (noscript) -->
      <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      <!-- End Google Tag Manager (noscript) -->
    `;
    document.body.insertAdjacentElement('afterbegin', noscript);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []);

  return null;
};

export default GoogleTagManager;